import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { homeInfo, homeInfoInterface } from "./constants";
import QA from "./qa";
import Schedule from "./Schedule";
import Tabs2 from "./Tabs";
import Navbar from "./Navbar";
import Rsvp from "./Rsvp";
import { useMediaQuery, useTheme } from "@mui/material";

const Homepage: React.FC = () => {
  const [queryParameters] = useSearchParams();
  const [language, setLanguage] = useState(queryParameters.get("lang") || localStorage.getItem("lang") || "en");  

  localStorage.setItem("lang", language);

  const [section, setSelectedSection] = useState(parseInt(localStorage.getItem("section") || "0"));

  const handleSectionChange = (sectionNumber: number) => {
    setSelectedSection(sectionNumber);
    localStorage.setItem("section", sectionNumber.toString());
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let homeInfoData: homeInfoInterface;
  if (language === "fr") {
    homeInfoData = homeInfo.fr; // Wrap the object inside an array
  } else if (language === "cn") {
    homeInfoData = homeInfo.cn; // Wrap the object inside an array
  } else {
    homeInfoData = homeInfo.en; // Wrap the object inside an array
  }

  const navigate = useNavigate();
  useEffect(() => {
    queryParameters.set("lang", language);
    navigate(window.location.pathname + "?" + queryParameters.toString(), { replace: true });
  }, [navigate, language, queryParameters]);

  useEffect(() => {
    if (section !== 4) {
      navigate("/?" + queryParameters.toString(), { replace: true });
    } else if (section === 4) {
      navigate("/rsvp?" + queryParameters.toString(), { replace: true });
    }
  }, [navigate, queryParameters, section]);

  const localhost = "http://localhost:8000";
  const vercel = "https://wedding-server-flame.vercel.app";
  // const url = localhost;
  const url = vercel;

  const getIPAddress = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();

    // get country and city of the ip address
    const response2 = await fetch(`https://ipapi.co/${data.ip}/json/`);
    const data2 = await response2.json();

    // send the ip address to the server
    await fetch(`${url}/ip`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ip: data2 }),
    });
  };

  useEffect(() => {
    getIPAddress();
  }, []);

  return (
    <div className="Background">
        { isSmallScreen && <Navbar handleSectionSelect={handleSectionChange}/>}
      <div className="LanguageDropdown">
        <select value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value="en">EN</option>
          <option value="fr">FR</option>
          <option value="cn">中文</option>
        </select>
      </div>
      <div className="Homepage">
        <h3>{homeInfoData.title}</h3>
        <h1>{homeInfoData.couple}</h1>
        {language === "cn" ? 
          <>
            <h3>{homeInfoData.theirwedding}</h3>
            <p>{homeInfoData.date}</p>
          </> :
          <>
            <p>{homeInfoData.date}</p>
            <p>
              {/* {Math.floor((new Date("2024-10-12").getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} {homeInfoData.daystogo} */}
            </p>
          </>
        }
        {/* <p>{homeInfoData.city}</p> */}
        { !isSmallScreen && <Tabs2 value={section} onChange={(_, sectionNumber) => handleSectionChange(sectionNumber)} /> }
        {0 === section && 
          <div>
            <img src={'pic.png'} alt="Wedding" style={{width:'clamp(300px, 90vw, 800px)', margin:'20px'}}/>
            <div>
              <div style={{paddingTop:'40px'}}>
                <button className="ButtonRsvp" style={{width:'clamp(70px, 45vw, 120px)'}} onClick={() => setSelectedSection(3)}>{homeInfoData.rsvp}</button>
              </div>
              <h2>{homeInfoData.weddingDay}</h2>
              <p>{homeInfoData.date}</p>

              {/* { language === "cn" && 
                <p> 倒计时：
                {Math.floor((new Date("2024-10-12").getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}
                天
                </p> 
              } */}

              <h5 style={{display: 'inline-block', width: 'clamp(100px, 80vw, 700px)', textAlign: 'justify', textAlignLast: 'center', marginTop: '0'}}>{homeInfoData.attire}</h5>

              <Schedule />
            </div>
          </div>


        }
        {1 === section && <>
          <h3 id="schedule">{homeInfoData.schedule}</h3>
          <Schedule />
        </>}

        {2 === section && <QA />}
        {3 === section && <Rsvp changeSection={() => setSelectedSection(0)}/>}
      

        <div style={{paddingTop: '100px', fontFamily: 'Prata', color: '#231f20', fontSize: '46px', fontWeight: 300, textTransform: 'uppercase', letterSpacing: '8px'}}>
          S&C
        <div/>
        <div style={{padding: '15px'}}>
          <hr style={{ width: 'clamp(70px, 45vw, 120px)', margin: '0 auto', borderTop: '1px solid black' }} />
        </div>
        <div style={{fontSize: '20px', letterSpacing: '2px'}}>
          2024-10-12
        </div>
      </div>

    </div>
    </div>
  );
};

export default Homepage;
